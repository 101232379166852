import person1 from "../../assets/person/1.jpeg";
import person10 from "../../assets/person/10.jpeg";
import person2 from "../../assets/person/2.jpeg";
import person3 from "../../assets/person/3.jpeg";
import person4 from "../../assets/person/4.jpeg";
import person5 from "../../assets/person/5.jpeg";
import person6 from "../../assets/person/6.jpeg";
import person7 from "../../assets/person/7.jpeg";
import person8 from "../../assets/person/8.jpeg";
import person9 from "../../assets/person/9.jpeg";
export const Users = [
  {
    id: 1,
    profilePicture: person1,
    username: "Safak Kocaoglu",
  },
  {
    id: 2,
    profilePicture: person2,
    username: "Janell Shrum",
  },
  {
    id: 3,
    profilePicture: person3,
    username: "Alex Durden",
  },
  {
    id: 4,
    profilePicture: person4,
    username: "Dora Hawks",
  },
  {
    id: 5,
    profilePicture: person5,
    username: "Thomas Holden",
  },
  {
    id: 6,
    profilePicture: person6,
    username: "Shirley Beauchamp",
  },
  {
    id: 7,
    profilePicture: person7,
    username: "Travis Bennett",
  },
  {
    id: 8,
    profilePicture: person8,
    username: "Kristen Thomas",
  },
  {
    id: 9,
    profilePicture: person9,
    username: "Gary Duty",
  },
  {
    id: 10,
    profilePicture: person10,
    username: "Safak Kocaoglu",
  },
];
