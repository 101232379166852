import React from "react";
import Home from "./components/Home";

import "./main.css";

function App() {
  return (
    <>
      <div>
        <Home />
      </div>
    </>
  );
}

export default App;
