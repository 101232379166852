import React from "react";
import HomePage from "../pages/HomePage/HomePage";

export default function Home() {
  return (
    <>
      <HomePage />
      {/* <ProfilePage /> */}
      {/* <LoginPage /> */}
      {/* <RegisterPage /> */}
    </>
  );
}
